<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form
    id="iiotLdapUserGroupRelationship"
    ref="userGroupRelationshipForm"
  >
    <v-row no-gutters>
      <v-col>
        <h3 class="mb-5">
          {{ $t('ldap.ldapSynchronization.userGroupRelationship') }}:
        </h3>
        <v-radio-group
          v-model="ldapData.relationship.type"
          :disabled="!isConnectionCorrect"
        >
          <v-radio
            id="iiotLdapUserGroupRelationshipUserRadio"
            :label="$t('ldap.ldapSynchronization.userGroupRelationshipRadio')"
            value="user-group"
          />
          <v-radio
            id="iiotLdapUserGroupRelationshipGroupRadio"
            :label="$t('ldap.ldapSynchronization.groupUserRelationshipRadio')"
            value="group-user"
          />
        </v-radio-group>
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="iiotLdapUserGroupRelationshipMember"
              v-model="ldapData.relationship.membership"
              :disabled="!isConnectionCorrect"
              :rules="[rules.required, rules.no_control_characters]"
              :label="$t('ldap.ldapSynchronization.membershipAttribute')"
            />
            <v-text-field
              id="iiotLdapUserGroupRelationshipGroupTarget"
              v-model="ldapData.relationship.target"
              :disabled="!isConnectionCorrect"
              :rules="[rules.required, rules.no_control_characters]"
              :label="$t('ldap.ldapSynchronization.targetAttribute')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { VALIDATION_REGEX } from '@/constants';

export default {
  computed: {
    rules() {
      return {
        required: (value) => !!value || this.$t('ldap.ldapSynchronization.required'),
        no_control_characters: (value) => VALIDATION_REGEX.NO_CONTROL_CHARACTERS.test(value) || this.$t('ldap.noControlCharacter'),
      };
    },
    ldapData() {
      return this.$store.getters['ldap/getDefaultLdap'];
    },
    isConnectionCorrect() {
      return this.$store.getters['ldap/getStatusOfTestConnection'];
    },
  },
};
</script>
