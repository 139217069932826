<!-- eslint-disable vue/no-deprecated-slot-attribute -->
<!-- eslint-disable vue/no-deprecated-v-bind-sync -->
<!--
*  TTTech nerve-management-system
*  Copyright(c) 2022. TTTech Industrial Automation AG.
*
*  ALL RIGHTS RESERVED.
*
*  Usage of this software, including source code, netlists, documentation,
*  is subject to restrictions and conditions of the applicable license
*  agreement with TTTech Industrial Automation AG or its affiliates.
*
*  All trademarks used are the property of their respective owners.
*
*  TTTech Industrial Automation AG and its affiliates do not assume any liability
*  arising out of the application or use of any product described or shown
*  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
*  make changes, at any time, in order to improve reliability, function or
*  design.
*
*  Contact Information:
*  support@tttech-industrial.com
*
*  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
*
* -->
<template>
  <v-form
    id="iiotLdapSynchronizationForm"
    ref="synchronizationForm"
  >
    <v-card
      outlined
    >
      <v-list-item>
        <v-list-item-content>
          <h3 class="mb-5">
            {{ $t('ldap.ldapSynchronization.title') }}
          </h3>
          <v-list-item-subtitle>
            <v-row
            class="mr-3"
            :class="{'ldap-sync-wrap': isMarginVisible,
            'd-flex justify-space-between': !isMarginVisible}"
            no-gutters
            v-resize="onResize"
          >
              <v-col
                cols="12"
                class="pa-0"
                lg="4"
              >
              <ldap-synchronization-groups
                ref="groups"
              />
              <nerve-dialog
                v-model="groupDialog"
                persistent
                scrollable
                max-width="1000"
              >
                <template v-slot:activator="{ attrs }">
                  <v-col cols="6">
                    <nerve-button
                      id="iiotLdapSynchronizationTestGroupsButton"
                      :disabled="!isConnectionCorrect"
                      v-bind="attrs"
                      :text="$t('ldap.ldapSynchronization.testGroupsSync')"
                      :class="{'test-groups-btn': !isMarginVisible}"
                      type-of-btn="action"
                      size="small"
                      @click-event="defaultPagination.page = 1,
                      defaultPagination.itemsPerPage = 10, testGroupsSync()"
                    />
                  </v-col>
                </template>
                <v-card class="ldap-dialog2">
                  <v-card-title>
                    {{ $t('ldap.ldapSynchronization.testGroupsQuery') }}
                  </v-card-title>
                  <v-card-text>
                    <v-data-table
                      id="iiotLdapSynchronizationGroupsTable"
                      :headers="groupColumns"
                      :items="queryResponseGroups"
                      :no-data-text="$t('ldap.ldapSynchronization.groupEmptyState')"
                      :footer-props="{'items-per-page-options': [10, 25, 50]}"
                      :server-items-length="totalRowsGroups"
                      :page.sync="defaultPagination.page"
                      :items-per-page.sync="defaultPagination.itemsPerPage"
                      @update:options="testGroupsSync"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <nerve-button
                      id="iiotLdapSynchronizationCancelButton"
                      :text="$t('ldap.ldapSynchronization.close')"
                      type-of-btn="cancel"
                      size="small"
                      @click-event="groupDialog = false"
                    />
                  </v-card-actions>
                </v-card>
              </nerve-dialog>
              </v-col>
              <v-col
                cols="12"
                class="pa-0"
                :class="{'pl-3': isMarginVisible}"
                lg="4"
              >
                <ldap-synchronization-users
                  ref="users"
                />
                <nerve-dialog
                  v-model="userDialog"
                  persistent
                  scrollable
                  max-width="1000"
                >
                  <template v-slot:activator="{ attrs }">
                    <nerve-button
                      id="iiotLdapSynchronizationTestUsers"
                      :disabled="!isConnectionCorrect"
                      v-bind="attrs"
                      :text="$t('ldap.ldapSynchronization.testUsersSync')"
                      type-of-btn="action"
                      size="small"
                      class="mt-6"
                      @click-event="defaultPagination.page = 1,
                      defaultPagination.itemsPerPage = 10, testUsersSync()"
                    />
                  </template>
                  <v-card class="ldap-dialog">
                    <v-card-title>
                      {{ $t('ldap.ldapSynchronization.testUsersQuery') }}
                    </v-card-title>
                    <v-card-text>
                      <v-card-text>
                        <v-data-table
                          id="iiotLdapSynchronizationUsersTable"
                          :headers="userColumns"
                          :items="queryResponseUsers"
                          :no-data-text="$t('ldap.ldapSynchronization.userEmptyState')"
                          :footer-props="{'items-per-page-options': [10, 25, 50]}"
                          :server-items-length="totalRowsUsers"
                          :page.sync="defaultPagination.page"
                          :items-per-page.sync="defaultPagination.itemsPerPage"
                          @update:options="testUsersSync"
                        />
                      </v-card-text>
                    </v-card-text>
                    <v-card-actions>
                      <nerve-button
                        id="iiotLdapSynchronizationClose"
                        :text="$t('ldap.ldapSynchronization.close')"
                        type-of-btn="cancel"
                        size="small"
                        @click-event="userDialog = false"
                      />
                    </v-card-actions>
                  </v-card>
                </nerve-dialog>
              </v-col>
              <v-col
                cols="12"
                class="pa-0"
                :class="{'pl-3 pt-3': isMarginVisible}"
                lg="3"
              >
                <ldap-user-group-relationship
                  ref="relationship"
                />
              </v-col>
            </v-row>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-card
      class="mt-5"
      outlined
    >
      <v-list-item>
        <v-list-item-content>
          <h3>{{ $t('ldap.ldapSynchronization.syncTitle') }}</h3>
          <v-list-item-subtitle class="pl-3">
            <v-row>
              <div
                id="iiotLdapSynchronizationRecurringSync"
                class="pl-3 mt-2 mr-3"
              >
                <v-switch
                  v-model="ldapData.recurringSync.recurringSync"
                  :disabled="!isConnectionCorrect"
                  :label="$t('ldap.ldapSynchronization.recurringSync')"
                  class="mt-6"
                />
              </div>
              <v-col
                v-if="ldapData.recurringSync.recurringSync"
                id="iiotLdapSynchronizationSchedule"
                cols="12"
                lg="2"
              >
                <v-select
                  v-model="ldapData.recurringSync.schedule"
                  :items="scheduleItems"
                  :label="$t('ldap.ldapSynchronization.schedule')"
                  :disabled="!ldapData.recurringSync.recurringSync || !isConnectionCorrect"
                  :menu-props="{ maxHeight: '110' }"
                  attach
                  @change="changeSchedule()"
                />
              </v-col>
              <v-col
                v-if="ldapData.recurringSync.recurringSync"
                cols="12"
                lg="1"
              >
                <div id="iiotLdapSynchronizationStartingTimeMenu">
                  <v-menu
                    v-if="ldapData.recurringSync.schedule === 'Starting time'"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="ldapData.recurringSync.time"
                    transition="scale-transition"
                    offset-y
                    max-width="300px"
                    min-width="280px"
                    attach
                    top
                  >
                    <template
                      v-slot:activator="{ on, attrs }"
                    >
                      <v-text-field
                        id="iiotLdapSynchronizationStartingTime"
                        v-model="ldapData.recurringSync.time"
                        v-bind="attrs"
                        :label="$t('ldap.ldapSynchronization.startingTime')"
                        :disabled="!ldapData.recurringSync.recurringSync || !isConnectionCorrect"
                        :rules="[rules.timeRequired]"
                        prepend-icon="access_time"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <div id="iiotLdapSynchronizationTimePicker">
                      <v-time-picker
                        v-model="ldapData.recurringSync.time"
                        full-width
                        @click:minute="$refs.menu.save(ldapData.recurringSync.time)"
                      />
                    </div>
                  </v-menu>
                  <v-text-field
                    v-if="ldapData.recurringSync.schedule === 'Interval'"
                    id="iiotLdapSynchronizationIntervalTime"
                    v-model="ldapData.recurringSync.time"
                    :label="$t('ldap.ldapSynchronization.intervalTime')"
                    :disabled="!ldapData.recurringSync.recurringSync || !isConnectionCorrect"
                    :rules="[rules.timeRequired, rules.timeIntervalRange]"
                    :min="0"
                    type="number"
                    prepend-icon="access_time"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  />
                </div>
              </v-col>
            </v-row>
            <v-fade-transition slot="append">
              <div
                v-if="ldapData.recurringSync.schedule === 'Starting time' &&
                ldapData.recurringSync.recurringSync"
              >
                <v-alert
                  id="iiotLdapSynchronizationAlert"
                  outlined
                  type="warning"
                  border="left"
                  class="mt-5"
                  width="550px"
                  dense
                >
                  {{ $t('ldap.ldapSynchronization.syncInformation') }}
                </v-alert>
              </div>
            </v-fade-transition>
            <nerve-button
              id="iiotLdapSynchronizationSyncButton"
              :text="$t('ldap.ldapSynchronization.sync')"
              :loading="syncResponse"
              :disabled="!isConnectionCorrect || !ldapData.fileName"
              class="mt-6"
              type-of-btn="action"
              size="small"
              @click-event="sync()"
            />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
  </v-form>
</template>

<script>
import { NerveButton, NerveDialog } from 'nerve-ui-components';
import ldapSynchronizationGroups from '@/components/ldap/ldap-synchronization-groups/ldapSynchronizationGroups.vue';
import ldapSynchronizationUsers from '@/components/ldap/ldap-synchronization-users/ldapSynchronizationUsers.vue';
import ldapUserGroupRelationship from '@/components/ldap/ldap-user-group-relationship/ldapUserGroupRelationship.vue';
import { VALIDATION_REGEX } from '@/constants';
import Logger from '@/utils/logger';

export default {
  components: {
    ldapSynchronizationGroups,
    ldapSynchronizationUsers,
    ldapUserGroupRelationship,
    NerveButton,
    NerveDialog,
  },
  data() {
    return {
      groups: 'groups',
      users: 'users',
      relationship: 'relationship',
      scheduleItems: ['Starting time', 'Interval'],
      time: null,
      menu: false,
      groupDialog: false,
      userDialog: false,
      groupsSearch: '',
      usersSearch: '',
      defaultPagination: { page: 1, itemsPerPage: 10 },
      isMarginVisible: false,
    };
  },
  computed: {
    rules() {
      return {
        timeRequired: (value) => !!value || this.$t('ldap.ldapSynchronization.timeRequired'),
        timeIntervalRange: (value) => VALIDATION_REGEX.INTERVAL_REGEX.test(value) || this.$t('ldap.ivalid_port_range'),
      };
    },
    ldapData() {
      return this.$store.getters['ldap/getDefaultLdap'];
    },
    isConnectionCorrect() {
      return this.$store.getters['ldap/getStatusOfTestConnection'];
    },
    syncResponse() {
      return this.$store.getters['ldap/syncResponse'];
    },
    queryResponseGroups() {
      return this.$store.getters['ldap/queryResponseGroups'];
    },
    queryResponseUsers() {
      return this.$store.getters['ldap/queryResponseUsers'];
    },
    totalRowsGroups() {
      return this.$store.getters['ldap/totalRowsGroups'];
    },
    totalRowsUsers() {
      return this.$store.getters['ldap/totalRowsUsers'];
    },
    groupColumns() {
      return [
        {
          text: this.$t('ldap.ldapSynchronization.name'),
          align: 'start',
          value: 'name',
        },
        {
          text: this.$t('ldap.ldapSynchronization.description'),
          value: 'description',
        },
      ];
    },
    userColumns() {
      return [
        {
          text: this.$t('ldap.ldapSynchronization.firstName'),
          align: 'start',
          value: 'firstName',
        },
        {
          text: this.$t('ldap.ldapSynchronization.lastName'),
          value: 'lastName',
        },
        {
          text: this.$t('ldap.ldapSynchronization.email'),
          value: 'email',
        },
        {
          text: this.$t('ldap.ldapSynchronization.username'),
          value: 'username',
        },
      ];
    },
  },
  methods: {
    async sync() {
      try {
        if (this.isSyncFormValid()) {
          await this.$store.dispatch('ldap/sync', this.$store.getters['ldap/getDefaultLdap']);
        }
      } catch (e) {
        Logger.error(e);
      }
    },
    async testGroupsSync(params = {}) {
      try {
        if (this.isTestUsersFormValid()) {
          this.groupDialog = true;
          await this.$store.dispatch('ldap/test_groups_sync', { ldap: this.$store.getters['ldap/getDefaultLdap'], ...params });
        }
      } catch (e) {
        Logger.error(e);
      }
    },
    async testUsersSync(params = {}) {
      try {
        if (this.isTestGroupsFormValid()) {
          this.userDialog = true;
          await this.$store.dispatch('ldap/test_users_sync', { ldap: this.$store.getters['ldap/getDefaultLdap'], ...params });
        }
      } catch (e) {
        Logger.error(e);
      }
    },
    changeSchedule() {
      try {
        this.$store.dispatch('ldap/set_time_to_zero');
      } catch (e) {
        Logger.error(e);
      }
    },
    isSyncFormValid() {
      return (this.$refs.synchronizationForm.validate()
      && this.$refs.groups.$refs.synchronizationGroupsForm.validate()
      && this.$refs.users.$refs.synchronizationUsersForm.validate()
      && this.$refs.relationship.$refs.userGroupRelationshipForm.validate());
    },
    isTestUsersFormValid() {
      return (this.$refs.groups.$refs.synchronizationGroupsForm.validate());
    },
    isTestGroupsFormValid() {
      return (this.$refs.users.$refs.synchronizationUsersForm.validate());
    },
    onResize() {
      this.isMarginVisible = window.innerWidth < 1264;
    },
  },
};
</script>
<style lang="scss" scoped>
  .ldap-sync-wrap {
    flex-wrap: wrap;
  }
  .test-groups-btn {
    position: absolute;
    bottom: 12px;
  }
  .ldap-dialog {
    max-height: 460px !important;
    min-height: 320px !important;
  }
  .ldap-dialog2{
    max-height: 500px !important;
    min-height: 320px !important;
  }

</style>
